@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

#faq {
    width: 100vw;
    height: auto;
    /* min-height: 100vw; */
    /* background-color:#6C7BA0; */
    /* background: linear-gradient(180deg, #0E183D 0%, #4A2450 85.42%); */
    background: #151B3C;
    /* padding: 100px 0; */
    /* background-image: url("/src/graphics/faq-bg.svg"); */
    background-repeat: no-repeat, repeat;
    background-size: contain;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 50px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    /* overflow-x:hidden;
    overflow-y:hidden; */
}

#faq h1 {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 112%;
    /* or 39px */
    
    letter-spacing: 0.08em;
    text-transform: uppercase;
    
    color: #FEFEFE;

    padding-top: 0px;
    padding-bottom: 100px;

    margin: auto;
    
    /* border: 0.5px solid #FEFEFE; */
}
#faq h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 112%;
    /* identical to box height, or 19px */

    letter-spacing: 0.05em;

    color: #FEFEFE;
}

#faq p {
    /* position: absolute; */
    /* width: 350px; */
    /* height: 114px; */
    /* left: 392px; */
    /* top: 6280px; */

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 112%;
    /* or 19px */

    letter-spacing: 0.05em;
    margin-top: 10px;
    margin-bottom: 40px;

    color: #FEFEFE;
}

.faq-text {
    max-width: 1000px;
    width: 70%;
    margin: auto;
    /* position: absolute; */
}

.faq-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.faq-col, .faq-col {
    width: 400px;
    padding: 0px 20px;
}

#left {
    position: absolute;
    height: 105%;
    top: 0;
    left: 0;
  }
  
#right {
    position: absolute;
    height: 75%;
    bottom: -40px;
    right: 0;
  }

.for-more {
    width: 100%;
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-button {
    color: white;
    font-size: 22px;
    background-color: #FF5732;
    padding: 16px 32px;
    border-radius: 50px;
}

.more-button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 860px) {
    #right {
        display: none;
    }
    #left {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .faq-col, .faq-col {
        width: 100%;
    }
    #faq {
        background-size: 1400px;
    }
}


@media only screen and (max-width: 400px) {
    #faq {
        background-size: 700px;
        background-position: bottom;
    }
}