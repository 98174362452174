@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

#tracks {
    width: 100vw;
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url("");
    background-repeat: no-repeat, repeat;
    background-position: bottom;
    background-color: #222F58;

    font-family: 'Montserrat', sans-serif;
    color: #FFFFFF;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

#top {
    margin-bottom: -25%;
    position: relative;
    width: 100vw;
}

h4 {
    font-size: 20px;
    font-weight: 500;
}

.tracks-container {
    margin-top: 10%;
    max-width: 1500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;
}

.tracks-container .track-table {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    justify-content: space-around;
}

.tracks-container .track {
    width: 15%;
    min-width: 300px;
    white-space: normal;
    min-height: 600px;
}

.tracks-container .track h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 22px;

    /* identical to box height */
    letter-spacing: 0.05em;

    text-align: center;
    padding-bottom: 10px;
}

.tracks-container .track img {
    height: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#blobs img {
    position: absolute;
    overflow-x: hidden;
}

#top {
    margin-top: -15%;
}

#bottom {
    margin-top: -15%;
    position: relative;
    width: 100vw;
}
