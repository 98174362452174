#about {
  width: 100vw;
  height: 130vh;
  background-color: var(--black);
  position: relative;
  display: flex;
  flex-direction: row;
  /*overflow: auto;*/
}

.about-container {
  max-width: 900px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

#about-text {
  width: 600px;
  color: #FEFEFE;

}

#about-title {
  text-align: center;
  /*font-family: Rany;*/
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 45px;
  line-height: 112%;
  color: #FEFEFE;

  /*margin-top: 50px;*/
  margin-bottom: 50px;
}

#about-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
}

#about-content {
  margin-bottom: 50px;
}

#left {
  position: absolute;
  height: 105%;
  top: 0;
  left: 0;
}

#right {
  position: absolute;
  height: 75%;
  bottom: -40px;
  right: 0;
}


@media only screen and (max-width: 400px) {
    #about {
        padding-bottom: 200px;
    }
}
