#schedule {
    width: 100vw;
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-image: url("/src/graphics/assets/schedule/clouds.svg");
    background-position: bottom;
    background-size: cover;
    background-color: #222F58;
}

#schedule-title {
    color: white;
}

.schedule-container {
    max-width: 1500px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    color: white;
    margin-top: 100px;
}

.schedule-container.days {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    justify-content: space-around;
}

.schedule-container .day {
    width: 40%;
    min-width: 300px;
    white-space: pre;
}

.schedule-container .day h3 {
    text-align: center;
    border-bottom: 2px solid var(--black);
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    #blobs #top-left {
        top: 550px;
        left: -150px;
    }

    #blobs #bottom-right {
        visibility: visible;
    }
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    border: 10px solid white;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

