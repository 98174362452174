#footer {
    background-color: #151B3C;
    height: 100px;
    width: 100vw;
}

#social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 40px;
}