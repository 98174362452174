@import url(https://fonts.googleapis.com/css2?family=Lora:wght@500;700&display=swap);
@import url(https://use.typekit.net/agn0giy.css);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* import Lora fonts (Google Fonts) */                                     /* import Lato fonts (Adobe Fonts) */

/* COLORS */
:root {
    --red: #FF5732;
    --pink: #FBE6DB;
    --blue: #65BABF;
    --yellow: #FFD754;
    --navy: #0C2B4A;
    --cream: #FAF0E3;
    --black: #0A112B;
}

.red {
    color: #FF5732;
    color: var(--red);
}


/* TEXT STYLES */
h1 {
    font-family: Lora;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 56px;
}

h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

body {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: inherit;
}

html, body {
    position: relative;
    /*width: 1440px;*/
    /*height: 5534px;*/

    background: #0A112B;

    background: var(--black);
    max-width: 100%;
    overflow-x: hidden;
}

#navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 75px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background: linear-gradient(#0c1b4b 0%, rgba(251, 230, 219, 0) 100%); */
    background: rgba(10, 17, 43);
    transition: background-color 200ms linear;
    transition: top 150ms linear;

    /* overflow: hidden; */
}

#navbar-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    margin-right: 70px;
    padding-top: 5px;
    /* margin: auto 70px auto auto; */
    /* padding-top: 17px;
    padding-right: 70px; */

    color: #FFFFFF;
}

#navbar #contents {
    width: inherit;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#navbar #content-left{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
}

.navbar-text {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: Lato;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 24px; */
    padding: 0px 15px;
    color: #FFFFFF;
}

.navbar-link {
    padding-top: 25px;
}

.white-line {
    justify-self: end;
    background-color: #FFFFFF;
    width: 100%;
    height: 5px;
    /* margin-top: 20px; */
    visibility: hidden;
}

#about-texts:hover .white-line {
    visibility: visible;
}

#tracks-texts:hover .white-line {
    visibility: visible;
}

#speakers-texts:hover .white-line {
    visibility: visible;
}

#schedule-texts:hover .white-line {
    visibility: visible;
}

#faq-texts:hover .white-line {
    visibility: visible;
}

#content-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-icon {
    cursor: pointer;
    display: none;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navbar-icon .navicon {
    background: #FFFFFF;
    display: block;
    height: 2px;
    position: relative;
    width: 18px;
}

.navbar-icon .navicon:before {
    top: 5px;
}
  
.navbar-icon .navicon:after {
    top: -5px;
}

.navbar-btn {
    display: none;
  }
  
.navbar-btn:checked ~ .navbar {
    max-height: 240px;
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon {
    background: transparent;
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
.navbar-btn:checked ~ .navbar-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
.navbar-btn:checked ~ .navbar-icon:not(.steps) .navicon:before,
.navbar-btn:checked ~ .navbar-icon:not(.steps) .navicon:after {
    top: 0;
  }

.navbar-icon .navicon:before,
.navbar-icon .navicon:after {
  background: #FFFFFF;
  content: '';
  display: none;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

@media (max-width: 700px) {
    #navbar #contents {
        flex-direction: column-reverse;
        /* margin-top: 515px; */
        margin-top: 0px;
    }

    #content-right {
        justify-content: space-between;
    }

    #navbar-title {
        margin-left: 30px;
        margin-right: 0px;
    }

    .navbar-icon {
        display: block;
    }
    
    .navbar-icon .navicon:before,
    .navbar-icon .navicon:after {
        display: block;
    }

    #navbar #content-left {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0px;
        max-height: 0;
        transition: max-height .2s ease-out;
        display: none;
        position: absolute;
    }

    .navbar-text {
        padding-left: 34px;
        background-color: rgba(10, 17, 43);
        width: 2000%;
    }

    #faq-texts {
        padding-bottom: 15px;
    }

    #about-texts:hover .white-line {
        visibility: hidden;
    }
    
    #tracks-texts:hover .white-line {
        visibility: hidden;
    }
    
    #speakers-texts:hover .white-line {
        visibility: hidden;
    }
    
    #schedule-texts:hover .white-line {
        visibility: hidden;
    }
    
    #faq-texts:hover .white-line {
        visibility: hidden;
    }
}
@-webkit-keyframes bg-change {
    from {opacity: 1;}
    to {opacity: 0;}
  }

@keyframes bg-change {
    from {opacity: 1;}
    to {opacity: 0;}
  }

#trees {
    position: absolute;
    width: 100%;
    z-index: 0;
}

#landing {
    width: 100vw;
    height: 100vh;
    position: relative;
}

#landing #header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    color: var(--red);
}

#landing-title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 175px;
    line-height: 199px;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

#landing #register-btn {
    background: #32467A;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

#landing #social-media {
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    font-size: 22px;
    color: #0C2B4A;
}

#landing h2 {
    margin: 8px 0;
}

@media only screen and (max-width: 400px) {
    #landing {
        padding-bottom: 100px;
    }

    #ideate {
        z-index: 1;
    }
}

#about {
  width: 100vw;
  height: 130vh;
  background-color: var(--black);
  position: relative;
  display: flex;
  flex-direction: row;
  /*overflow: auto;*/
}

.about-container {
  max-width: 900px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

#about-text {
  width: 600px;
  color: #FEFEFE;

}

#about-title {
  text-align: center;
  /*font-family: Rany;*/
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 45px;
  line-height: 112%;
  color: #FEFEFE;

  /*margin-top: 50px;*/
  margin-bottom: 50px;
}

#about-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
}

#about-content {
  margin-bottom: 50px;
}

#left {
  position: absolute;
  height: 105%;
  top: 0;
  left: 0;
}

#right {
  position: absolute;
  height: 75%;
  bottom: -40px;
  right: 0;
}


@media only screen and (max-width: 400px) {
    #about {
        padding-bottom: 200px;
    }
}

#tracks {
    width: 100vw;
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url("");
    background-repeat: no-repeat, repeat;
    background-position: bottom;
    background-color: #222F58;

    font-family: 'Montserrat', sans-serif;
    color: #FFFFFF;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

#top {
    margin-bottom: -25%;
    position: relative;
    width: 100vw;
}

h4 {
    font-size: 20px;
    font-weight: 500;
}

.tracks-container {
    margin-top: 10%;
    max-width: 1500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;
}

.tracks-container .track-table {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    justify-content: space-around;
}

.tracks-container .track {
    width: 15%;
    min-width: 300px;
    white-space: normal;
    min-height: 600px;
}

.tracks-container .track h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 22px;

    /* identical to box height */
    letter-spacing: 0.05em;

    text-align: center;
    padding-bottom: 10px;
}

.tracks-container .track img {
    height: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#blobs img {
    position: absolute;
    overflow-x: hidden;
}

#top {
    margin-top: -15%;
}

#bottom {
    margin-top: -15%;
    position: relative;
    width: 100vw;
}

#schedule {
    width: 100vw;
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-image: url(/static/media/clouds.c827c46d.svg);
    background-position: bottom;
    background-size: cover;
    background-color: #222F58;
}

#schedule-title {
    color: white;
}

.schedule-container {
    max-width: 1500px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    color: white;
    margin-top: 100px;
}

.schedule-container.days {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    justify-content: space-around;
}

.schedule-container .day {
    width: 40%;
    min-width: 300px;
    white-space: pre;
}

.schedule-container .day h3 {
    text-align: center;
    border-bottom: 2px solid var(--black);
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    #blobs #top-left {
        top: 550px;
        left: -150px;
    }

    #blobs #bottom-right {
        visibility: visible;
    }
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    border: 10px solid white;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}


#faq {
    width: 100vw;
    height: auto;
    /* min-height: 100vw; */
    /* background-color:#6C7BA0; */
    /* background: linear-gradient(180deg, #0E183D 0%, #4A2450 85.42%); */
    background: #151B3C;
    /* padding: 100px 0; */
    /* background-image: url("/src/graphics/faq-bg.svg"); */
    background-repeat: no-repeat, repeat;
    background-size: contain;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 50px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    /* overflow-x:hidden;
    overflow-y:hidden; */
}

#faq h1 {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 112%;
    /* or 39px */
    
    letter-spacing: 0.08em;
    text-transform: uppercase;
    
    color: #FEFEFE;

    padding-top: 0px;
    padding-bottom: 100px;

    margin: auto;
    
    /* border: 0.5px solid #FEFEFE; */
}
#faq h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 112%;
    /* identical to box height, or 19px */

    letter-spacing: 0.05em;

    color: #FEFEFE;
}

#faq p {
    /* position: absolute; */
    /* width: 350px; */
    /* height: 114px; */
    /* left: 392px; */
    /* top: 6280px; */

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 112%;
    /* or 19px */

    letter-spacing: 0.05em;
    margin-top: 10px;
    margin-bottom: 40px;

    color: #FEFEFE;
}

.faq-text {
    max-width: 1000px;
    width: 70%;
    margin: auto;
    /* position: absolute; */
}

.faq-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.faq-col, .faq-col {
    width: 400px;
    padding: 0px 20px;
}

#left {
    position: absolute;
    height: 105%;
    top: 0;
    left: 0;
  }
  
#right {
    position: absolute;
    height: 75%;
    bottom: -40px;
    right: 0;
  }

.for-more {
    width: 100%;
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-button {
    color: white;
    font-size: 22px;
    background-color: #FF5732;
    padding: 16px 32px;
    border-radius: 50px;
}

.more-button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 860px) {
    #right {
        display: none;
    }
    #left {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .faq-col, .faq-col {
        width: 100%;
    }
    #faq {
        background-size: 1400px;
    }
}


@media only screen and (max-width: 400px) {
    #faq {
        background-size: 700px;
        background-position: bottom;
    }
}
#speakers {
  background-color: #32467A;
  position: relative;
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -30%;
}

 h1{
    color: #FEFEFE;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 35px;
}
h3 {
  font-size: 25px;
  font-weight: 500;
}
h4 {
  font-size: 20px;
  font-weight: 500;
}
#speakers-container{
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;   /*review!!*/
    width: 60%;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}
.active-anim {
    opacity: 1;
  }

.slide img{
    width: 270px;
    height: 270px;
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}

.slide h3, h4{
    color: #FEFEFE;
    -webkit-margin-before: 0.7em;
            margin-block-start: 0.7em;
    -webkit-margin-after: 0.3em;
            margin-block-end: 0.3em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

.btn-slide {
    cursor: pointer;
    border: none;
    background: none;
}

.next {
    position: absolute;
    top: 50%;
    right: 15%;
    font-size: 3rem;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.prev {
    position: absolute;
    top: 50%;
    left: 15%;
    font-size: 3rem;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.container-dots {
    position: relative;
    display: flex;
    bottom: 50px;

  }
  .dot {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    margin: 0 5px;
    background: #D5DDF0;
    cursor: pointer;
  }
  .dot.active {
    background-image: url(/static/media/currentDot.848e5e58.svg);
    background-color: #32467A;
    object-fit: cover;

    width: 39.37px;
    height: 41px;
    -webkit-transform: translateY(-33%);
            transform: translateY(-33%);
  }

  @media only screen and (max-width: 700px) {
    .slide img{
      width: 200px;
      height: 200px;

    }
    h3 {
      font-size: 20px;
      font-weight: 500;
    }
    h4 {
      font-size: 15px;
      font-weight: 500;
    }
  }

#footer {
    background-color: #151B3C;
    height: 100px;
    width: 100vw;
}

#social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 40px;
}
#purpose {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--pink);
}

.purpose-container {
    max-width: 700px;
    width: 70%;
    margin: 50px auto 0;
}

#scroll-image {
    border-radius: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 100%;
}

.alice-carousel {
    margin-bottom: 50px;
}

.purpose-img {
    border-radius: 8px;
}

@media only screen and (max-width: 400px) {
    #purpose {
        padding-bottom: 100px;
    }
}
#sponsors {
    width: 100vw;
    background-color: #6b84c8;
    padding: 10px 0 100px;
}

.sponsors-container {
    max-width: 1200px;
    margin: 0px auto;
    width: 80%;
}

.sponsor-logos {
    text-align: center;
    justify-content: space-between;
    vertical-align: middle;
}

.sponsor-logos img {
    margin: 40px;
}

@media only screen and (max-width: 400px) {
    .sponsor-title {
        margin-left: 10%;
    }

    .sponsors-container {
        width: 100%;
    }
}

