@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

#speakers {
  background-color: #32467A;
  position: relative;
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -30%;
}

 h1{
    color: #FEFEFE;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 35px;
}
h3 {
  font-size: 25px;
  font-weight: 500;
}
h4 {
  font-size: 20px;
  font-weight: 500;
}
#speakers-container{
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;   /*review!!*/
    width: 60%;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}
.active-anim {
    opacity: 1;
  }

.slide img{
    width: 270px;
    height: 270px;
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}

.slide h3, h4{
    color: #FEFEFE;
    margin-block-start: 0.7em;
    margin-block-end: 0.3em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.btn-slide {
    cursor: pointer;
    border: none;
    background: none;
}

.next {
    position: absolute;
    top: 50%;
    right: 15%;
    font-size: 3rem;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.prev {
    position: absolute;
    top: 50%;
    left: 15%;
    font-size: 3rem;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.container-dots {
    position: relative;
    display: flex;
    bottom: 50px;

  }
  .dot {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    margin: 0 5px;
    background: #D5DDF0;
    cursor: pointer;
  }
  .dot.active {
    background-image: url("../../graphics/assets/speakers/currentDot.svg");
    background-color: #32467A;
    object-fit: cover;

    width: 39.37px;
    height: 41px;
    transform: translateY(-33%);
  }

  @media only screen and (max-width: 700px) {
    .slide img{
      width: 200px;
      height: 200px;

    }
    h3 {
      font-size: 20px;
      font-weight: 500;
    }
    h4 {
      font-size: 15px;
      font-weight: 500;
    }
  }
