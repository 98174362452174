@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

@keyframes bg-change {
    from {opacity: 1;}
    to {opacity: 0;}
  }

#trees {
    position: absolute;
    width: 100%;
    z-index: 0;
}

#landing {
    width: 100vw;
    height: 100vh;
    position: relative;
}

#landing #header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    color: var(--red);
}

#landing-title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 175px;
    line-height: 199px;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    z-index: 1;
    color: #FFFFFF;
}

#landing #register-btn {
    background: #32467A;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

#landing #social-media {
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    font-size: 22px;
    color: #0C2B4A;
}

#landing h2 {
    margin: 8px 0;
}

@media only screen and (max-width: 400px) {
    #landing {
        padding-bottom: 100px;
    }

    #ideate {
        z-index: 1;
    }
}
